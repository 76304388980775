import { SearchOffersAsYouType } from '@lambda/commons/apis/elasticsearch/types';
import { UrlObject } from 'url';
import { REEBELO_DOMAINS, ReebeloStoreT } from '@lambda/reebelo';
import { round } from 'lodash-es';
import { tagToSelection } from './collections/catalogue/helpers';

export function formatOfferTitle(
  offerTitle: string,
  tags: Record<string, any>,
) {
  const reStrColor = `${
    tags.color?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') || ''
  }\\s`;
  const reColor = new RegExp(reStrColor, 'g');

  return offerTitle
    .replace(tags.storage || '', '')
    .replace(tags.condition || '', '')
    .replace('Very Good', '')
    .replace(tags.color != null ? reColor : '', tags.color != null ? ' ' : '')
    .replace(/ - /g, ' ')
    .trim();
}

export const formatOfferUri = (
  productHandle: string,
  tags: string[],
  mvno?: string | undefined,
  deliveryBy?: boolean,
): UrlObject => ({
  pathname: '/products/[handle]',
  query: { handle: productHandle, ...tagToSelection(tags), mvno, deliveryBy },
});

export const formatOfferUrlString = (
  productHandle: string,
  tags: string[],
  store: ReebeloStoreT,
  mvno?: string,
): string => {
  const baseUrl = REEBELO_DOMAINS[store];
  const urlParams = {
    ...tagToSelection(tags),
  };

  if (mvno) urlParams.mvno = mvno;

  return `${baseUrl}/products/${productHandle}?${new URLSearchParams(
    urlParams,
  )}`;
};

export const getOfferUri = (
  searchResult: Pick<
    SearchOffersAsYouType,
    'handle' | 'tags' | 'variantId' | 'productType'
  >,
  deliveryBy?: boolean,
): UrlObject => {
  if (searchResult.productType?.toLowerCase() === 'gift cards') {
    return {
      pathname: '/products/reebelos-gift-card',
      query: {
        variant: searchResult.variantId,
      },
    };
  }

  const urlObject = formatOfferUri(
    searchResult.handle,
    searchResult.tags,
    undefined,
    deliveryBy,
  );

  return urlObject;
};

export const calculateSavings = (
  actualPrice: number,
  comparePrice: number | undefined | null,
) => {
  const showComparePrice = comparePrice != null && comparePrice > actualPrice;

  if (!showComparePrice) return 0;

  const percentageSaving = round(
    ((Number(comparePrice) - Number(actualPrice)) * 100) / Number(comparePrice),
  );

  return percentageSaving;
};
