import Link from 'next/link';
import Image from 'next/future/image';
import AccountIcon from '@/public/icons/menu/account.svg';
import { handleMixpanelMenuClick } from '../utils';
import { useCustomerContext } from '@/lib/customer/CustomerContext';

const AccountCta = ({
  closeMenuPanel,
}: {
  closeMenuPanel: (val?: boolean) => void;
}) => {
  const { customer } = useCustomerContext();
  const label = customer ? 'Account' : 'Sign in';
  const route = customer ? '/account/orders' : '/account/login';

  return (
    <Link href={route}>
      <button
        className="flex w-full items-center px-4 py-3 hover:bg-gray-300 focus:outline-none sm:px-5"
        onClick={() => {
          handleMixpanelMenuClick({
            menuLevel: 0,
            title: label,
            handle: route,
          });
          closeMenuPanel();
        }}
      >
        <Image
          src={AccountIcon}
          alt="Sign in"
          height={25}
          width={25}
          className="mr-3"
        />
        <span>{label}</span>
      </button>
    </Link>
  );
};

export default AccountCta;
