import { pickBy } from 'lodash-es';
import { ProductGroup } from '@lambda/apis/src/product-group/types';
import { MenuItem } from '@/settings/types';
import type { CollectionSummaries } from '@/api/collections/summary';
import i18n from '@/settings/i18n';

const t = i18n.init();

export const filterInactiveMenus = (
  menu: MenuItem[],
  handles: CollectionSummaries,
) => {
  const activeHandles = pickBy(handles, (item) => item != null);

  return menu
    .filter((item) => activeHandles[item.handle] != null)
    .map((item) => ({
      handle: item.handle,
      menuSectionHeader: item.menuSectionHeader || false,
      links: item.links?.filter(
        (menuItem) => activeHandles[menuItem.handle] != null,
      ),
    }));
};

// Deprecated
export const extractCollectionTitle = (
  handle: string | undefined | null,
  productGroups?: Record<string, ProductGroup>,
) => {
  if (handle) {
    const handleToTitleMap = {
      'more-devices': t`All Other Products`,
      'other-smartphones': t`See All Smartphones`,
    };

    return handleToTitleMap[handle as keyof typeof handleToTitleMap]
      ? handleToTitleMap[handle as keyof typeof handleToTitleMap]
      : `All ${productGroups?.[handle]?.name}`;
  }

  return '';
};

export const customizeCollectionTitle = (
  handle: string | undefined | null,
  title: string,
) => {
  if (handle) {
    const handleToTitleMap = {
      'more-devices': t`All Other Products`,
      'other-smartphones': t`See All Smartphones`,
    };

    return handleToTitleMap[handle as keyof typeof handleToTitleMap]
      ? handleToTitleMap[handle as keyof typeof handleToTitleMap]
      : `All ${title}`;
  }

  return '';
};