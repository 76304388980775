import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LayoutProps } from '@/components/HeaderMenu/MenuPanel/types';
import { chunkArray } from '@/components/HeaderMenu/MenuPanel/utils';
import ShowMore from '@/components/HeaderMenu/MenuPanel/common/ShowMore';
import Collection from '@/components/HeaderMenu/MenuPanel/layouts/Collection';
import Nav from '@/components/HeaderMenu/MenuPanel/layouts/Nav';
import Accessories from './Accessories';

const Layout = ({ selectedSubMenu, closeMenuPanel }: LayoutProps) => {
  const isCollectionLayout = selectedSubMenu?.layout === 'collection';
  const isNavLayout = selectedSubMenu?.layout === 'nav';
  const isAccessoriesLayout = selectedSubMenu?.layout === 'accessories';

  const generateSliceEnd = useCallback(() => {
    if (isCollectionLayout) return 3;
    if (isNavLayout) return 2;
    if (isAccessoriesLayout) return 1;

    return 0;
  }, [isCollectionLayout, isNavLayout]);

  const SLICE_END = generateSliceEnd();

  const [cardHeight, setCardHeight] = useState(0);
  const [sliceEnd, setSliceEnd] = useState(3);
  const formattedMenu = chunkArray(selectedSubMenu?.links ?? [], SLICE_END);

  const [showMore, setShowMore] = useState(formattedMenu.length > 3);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef) {
      const containerHeight = containerRef.current?.clientHeight ?? 0;

      setCardHeight(containerHeight / 3);
    }
  }, [containerRef]);

  const slicedMenu = formattedMenu.slice(0, sliceEnd);

  const scrollToBottom = useCallback(() => {
    containerRef?.current?.scrollBy({
      behavior: 'smooth',
      top: containerRef?.current?.scrollHeight,
    });
  }, [containerRef]);

  const onShowMoreClick = useCallback(() => {
    if (formattedMenu.length && selectedSubMenu?.links?.length) {
      if (sliceEnd + 3 >= selectedSubMenu.links.length / SLICE_END)
        setShowMore(false);

      setSliceEnd(sliceEnd + 3);
      setTimeout(scrollToBottom, 100);
    }
  }, [sliceEnd, formattedMenu, selectedSubMenu, SLICE_END, scrollToBottom]);

  useEffect(() => {
    // Reset State on Data Change
    setShowMore(formattedMenu.length > 3);
    setSliceEnd(3);
  }, [selectedSubMenu?.handle]);

  return (
    <>
      <div
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className="flex-1 overflow-y-auto"
        ref={containerRef}
        id="scroll-container"
      >
        <table className="table w-full table-fixed">
          <colgroup>
            <col />
            {(isCollectionLayout || isNavLayout) && <col />}
            {isCollectionLayout && <col />}
          </colgroup>
          {slicedMenu.map((itemMenu) => {
            if (isNavLayout) {
              return (
                <Nav
                  key={itemMenu[0].handle}
                  items={itemMenu}
                  closeMenuPanel={closeMenuPanel}
                  cardHeight={cardHeight}
                />
              );
            }

            if (isAccessoriesLayout) {
              return (
                <Accessories items={itemMenu} closeMenuPanel={closeMenuPanel} />
              );
            }

            return (
              <Collection
                items={itemMenu}
                closeMenuPanel={closeMenuPanel}
                cardHeight={cardHeight}
              />
            );
          })}
        </table>
      </div>
      <div>
        <ShowMore show={showMore} onClick={onShowMoreClick} />
      </div>
    </>
  );
};

export default Layout;
