import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import AccessBar from './AccessBar';
import HeaderMenu from '../../HeaderMenu';
import SearchBarMobile from './SearchMobile/SearchBarMobile';

export default function Header() {
  const [showMenuPanel, setShowMenuPanel] = useState(false);

  const router = useRouter();
  const { pathname } = router;
  const [isHomepage, setHomepage] = useState(false);

  useEffect(() => {
    if (pathname === '/') setHomepage(true);
    else setHomepage(false);
  }, [pathname]);

  const cartIconBubbleClass = isHomepage ? 'bg-white' : 'bg-teal-500';
  const containerRef = useRef<HTMLDivElement>(null);

  const hideMenu = router.pathname === '/checkout/[checkoutId]';

  return (
    <>
      <div
        ref={containerRef}
        id="header"
        className={cn(
          'sticky top-0 z-50 w-full bg-white pt-4 drop-shadow-sm transition duration-300 lg:bg-white lg:pt-2 lg:drop-shadow-md',
          { 'pb-4 lg:pb-2': hideMenu },
        )}
      >
        <AccessBar
          toggle={() => setShowMenuPanel((b) => !b)}
          className={cn(
            'reebelo-container mb-0 grid grid-cols-3 items-center justify-between gap-4 xxs:gap-5 sm:flex sm:grid-cols-none  lg:justify-start',
            {
              'lg:mb-2': !hideMenu,
            },
          )}
          cartIconBubbleClass={cartIconBubbleClass}
          hideMenu={hideMenu}
        />

        {!hideMenu && (
          <>
            <SearchBarMobile
              className="mt-2"
              toggle={() => setShowMenuPanel(false)}
              containerRef={containerRef}
            />
            <div
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="lg:flex lg:border"
            >
              <div
                // eslint-disable-next-line tailwindcss/no-custom-classname
                className="lg:reebelo-container"
                id="inline-menu"
              >
                <HeaderMenu
                  setShowMenuPanel={setShowMenuPanel}
                  showMenuPanel={showMenuPanel}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
