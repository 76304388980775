import cn from 'classnames';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import Image from 'next/future/image';
import useSWR from 'swr';
import settings from '@/settings';
import { MenuItem } from '@/settings/types';
import i18n from '@/settings/i18n';
import useSubscribeFF from '@/lib/useSubscribeFF';
import BurgerIcon from '@/public/icons/menu/burger.svg';
import MenuPanel from './MenuPanel';
import { triggerMixpanelEvent } from '@/lib/mixpanelUtils';
import FireIcon from '@/public/icons/collection/fire.svg';
import { getPageUrl } from '../collections/helpers';
import { generateBuybackUrl } from '@/lib/menu/helpers';
import templateMenu from '@/settings/menus';
import { customizeCollectionTitle } from '../commons/Header/MenuBar/helpers';

const t = i18n.init();

const DynamicMenuItem = dynamic(() => import('./DynamicMenuItem'));

function getSubmenuItemClassesByLevel(level: number) {
  const commonSubMenuClasses = 'submenu absolute hidden w-64 flex-col';

  switch (level) {
    case 0:
      return 'flex w-full lg:text-sm text-[.8rem] main-menu overflow-x-auto hide-scrollbar px-5 sm:px-8 lg:px-0';

    case 1:
      return `${commonSubMenuClasses} -left-4 top-full bg-white`;

    case 2:
      return `${commonSubMenuClasses} left-full top-0 min-h-full max-h-[calc(100vh_-_130px)] overflow-y-auto bg-gray-200`;

    default:
      return `${commonSubMenuClasses} left-full bg-white top-0 h-full overflow-y-auto`;
  }
}

type MenuClickHandlerProps = {
  handle: string;
  title: string;
  menuLevel: number;
  index: number;
  psku?: string;
};

function handleMenuClick(menuClickProps: MenuClickHandlerProps) {
  const { handle, psku, title, menuLevel, index } = menuClickProps;

  triggerMixpanelEvent({
    eventName: `Menu Item Clicked`,
    props: {
      'Section Type':
        menuLevel > 0 ? 'Catalog Vertical Tray' : 'Featured Horizonal Bar',
      'Menu Name': handle,
      'Item Last Level Position': menuLevel > 0 ? index : undefined,
      'Item Name': menuLevel > 1 ? title : undefined,
      'Item PSKU': psku,
    },
  });
}

const Buyback = ({
  menuLevel,
  endpoint,
  view,
}: {
  menuLevel: number;
  endpoint: any;
  view: 'desktop' | 'mobile';
}) => {
  if (settings.buyback_exist && menuLevel === 0) {
    return (
      <>
        {view === 'mobile' ? (
          <li
            className={cn('flex shrink-0 items-center lg:hidden', {
              'mr-3': view === 'mobile' && settings.store !== 'reebelo-au',
            })}
          >
            <a
              className="rounded-full border border-gray-700 px-2 py-1 text-xs hover:[text-shadow:0.5px_0px_0px_#000] lg:text-sm"
              href={endpoint}
              onClick={() =>
                handleMenuClick({
                  handle: endpoint,
                  title: 'Sell Your Device',
                  menuLevel,
                  index: 0,
                })
              }
            >
              {t`Sell Your Device`}
            </a>
          </li>
        ) : (
          <div className="ml-3 hidden shrink-0 items-center lg:flex">
            <a
              className="rounded-full border border-gray-700 px-2 py-1 text-xs hover:[text-shadow:0.5px_0px_0px_#000] lg:text-sm"
              href={endpoint}
              onClick={() =>
                handleMenuClick({
                  handle: endpoint,
                  title: 'Sell Your Device',
                  menuLevel,
                  index: 0,
                })
              }
            >
              {t`Sell Your Device`}
            </a>
          </div>
        )}
      </>
    );
  }

  return null;
};

type MenuProps = {
  menuData: MenuItem[] | null;
  menuLevel: number;
  setShowMenuPanel?: (val: boolean) => void;
};

export const Menu = ({ menuData, menuLevel, setShowMenuPanel }: MenuProps) => {
  const { properties: dealsMenuTitleFFProps } =
    useSubscribeFF('deals-page-title');

  if (!menuData || menuData.length === 0) return null;

  const dealsMenuTitle =
    (dealsMenuTitleFFProps?.menu?.value as string) ?? 'Deals';

  return (
    <div className={cn({ 'flex items-center': menuLevel === 0 })}>
      <ul className={getSubmenuItemClassesByLevel(menuLevel)}>
        {settings.store !== 'reebelo-au' && (
          <Buyback
            menuLevel={menuLevel}
            endpoint={generateBuybackUrl()}
            view="mobile"
          />
        )}
        <li className={cn('menu-item relative hidden lg:block')}>
          {menuLevel === 0 && (
            <button
              onClick={() =>
                typeof setShowMenuPanel === 'function' && setShowMenuPanel(true)
              }
              className="flex w-full items-center justify-center px-3 py-4 pl-0 pr-10 outline-none hover:[text-shadow:0.5px_0px_0px_#000]"
            >
              <div className="-mt-0.5 mr-2 flex h-[12px] w-[12px] items-center justify-center">
                <span className="h-[12px] w-[12px]">
                  <Image
                    src={BurgerIcon}
                    height={12}
                    width={12}
                    alt="All Items button"
                    className="h-full w-full"
                  />
                </span>
              </div>
              <span className="whitespace-nowrap">All Items</span>
            </button>
          )}
        </li>
        {menuLevel === 0 && (
          <li className={cn('menu-item relative')}>
            <Link href="/collections/hot-deals">
              <a
                className="flex w-full px-3 py-4 pl-0 pr-6 hover:[text-shadow:0.5px_0px_0px_#000]"
                onClick={() => {
                  handleMenuClick({
                    handle: 'hot-deals',
                    title: 'Hot Deals',
                    menuLevel,
                    index: 0,
                  });
                }}
              >
                <div className="flex items-center justify-center whitespace-nowrap">
                  <span className="mr-1.5">{dealsMenuTitle} </span>
                  <div className="-mt-0.5 h-4 w-4">
                    <Image priority src={FireIcon} alt="Deal" />
                  </div>
                </div>
              </a>
            </Link>
          </li>
        )}
        {menuData.map(
          ({ handle, menuTitle, links, menuSectionHeader }, index) => {
            const inStockChildLinks = links as unknown as MenuItem[];

            if (handle !== null && menuSectionHeader) {
              return (
                <li
                  key={handle}
                  className={cn(
                    'menu-item',
                    { relative: menuLevel === 0 },
                    { 'hover:bg-gray-200': menuLevel !== 0 },
                  )}
                >
                  <Link href={getPageUrl(handle)} prefetch={false}>
                    <a
                      className={cn(
                        'flex w-full px-3 font-bold hover:[text-shadow:0.5px_0px_0px_#000]',
                        { 'py-4': menuLevel === 0 },
                        { 'py-2 hover:bg-gray-200': menuLevel !== 0 },
                        { 'pl-0': menuLevel === 0 && index === 0 },
                      )}
                      onClick={() =>
                        handleMenuClick({
                          handle,
                          title: 'reebelos-gift-card',
                          menuLevel,
                          index,
                        })
                      }
                    >
                      <span>{ customizeCollectionTitle(handle, menuTitle) }</span>
                    </a>
                  </Link>
                </li>
              );
            }

            return (
              <DynamicMenuItem
                key={handle}
                handle={handle}
                menuTitle={menuTitle}
                menuLevel={menuLevel}
                links={links}
                inStockChildLinks={inStockChildLinks}
                index={index}
                handleMenuClick={handleMenuClick}
              />
            );
          },
        )}
        {settings.store === 'reebelo-au' && (
          <Buyback
            menuLevel={menuLevel}
            endpoint={generateBuybackUrl()}
            view="mobile"
          />
        )}
      </ul>

      <Buyback
        menuLevel={menuLevel}
        endpoint={generateBuybackUrl()}
        view="desktop"
      />
    </div>
  );
};

export default function HeaderMenu({
  showMenuPanel,
  setShowMenuPanel,
}: {
  showMenuPanel: boolean;
  setShowMenuPanel: (val: boolean) => void;
}) {
  const { data } = useSWR('/api/header-menu');

  if (!data) return null;

  const menuItems: MenuItem[] = data || templateMenu;

  return (
    <>
      <Menu
        menuData={menuItems}
        menuLevel={0}
        setShowMenuPanel={setShowMenuPanel}
      />
      <MenuPanel showMenu={showMenuPanel} setShowMenu={setShowMenuPanel} />
    </>
  );
}
